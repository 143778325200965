import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteStatusEnum } from '@app/enums/site-status.enum';
import { ClinTrakStudySiteViewModel } from '@models/clintrak';
import {
    Address,
    Site,
    SiteCRC,
    SiteInfo,
    SitePayment,
    SiteServices,
    SiteStatus,
    createNewSite,
    updateSiteProperties,
} from '@models/site';
import { AdminSiteServices } from '@services/admin/admin-site.service';
import { ClinTrakDataService } from '@services/clintrak/clintrak-data.service';
import { CurrentlyEditedCardService } from '@services/currently-edited-card/currently-edited-card.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { ClinTrakDataStateService } from '@services/state-management/clintrak-data-state.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { transformToSiteDataModel } from '@services/transforms/site-transform';
import { UserService } from '@services/user/user.service';
import { Subject, combineLatest, finalize, iif, switchMap, take, takeUntil, tap } from 'rxjs';
import { MedpaceMessageModalComponent } from 'src/app/components/molecules/modals/medpace-message-modal/medpace-message-modal.component';

@Component({
    selector: 'app-create-edit-site',
    templateUrl: './create-edit-site.component.html',
    styleUrls: ['./create-edit-site.component.scss'],
})
export class CreateEditSiteComponent implements OnInit, OnDestroy {
    site: Site;
    isUpdate: boolean = false;
    private componentDestroyed$: Subject<boolean> = new Subject();

    constructor(
        private activeRoute: ActivatedRoute,
        private router: Router,
        private siteService: AdminSiteServices,
        public dialog: MatDialog,
        private snackbarService: SnackbarService,
        private stateService: SiteStateService,
        private userService: UserService,
        private cardService: CurrentlyEditedCardService,
        private location: Location,
        private clinTrakDataService: ClinTrakDataService,
        private clinTrakDataStateService: ClinTrakDataStateService
    ) {}
    public readonly create$ = combineLatest({
        routeParamMap: this.activeRoute.paramMap,
        user: this.userService.getUser(),
    }).pipe(
        take(1),
        tap((result) => {
            const studyId = +result.routeParamMap.get('id');
            this.site = createNewSite();
            const clinTrakSite: ClinTrakStudySiteViewModel = this.router.lastSuccessfulNavigation?.extras?.state?.event;
            if (clinTrakSite) this.setClinTrakData(clinTrakSite);
            else this.stateService.setNewSite(this.site);
            if (result.user.isAdmin || result.user.isSuperAdmin) this.stateService.setUsers(studyId);

            this.site.studyId = studyId;
        })
    );
    public readonly edit$ = combineLatest({
        routeParamMap: this.activeRoute.paramMap,
        user: this.userService.getUser(),
    }).pipe(
        take(1),
        tap((result) => {
            const siteId = +result.routeParamMap.get('siteId');
            result.user.isAdmin || result.user.isSuperAdmin
                ? this.stateService.setSiteAndStudyUsers(siteId)
                : this.stateService.setSite(siteId);
        }),
        switchMap((result) => this.stateService.getSite().pipe(takeUntil(this.componentDestroyed$))),
        tap((site) => (this.site = site))
    );

    public readonly vm$ = this.activeRoute.url.pipe(
        switchMap((url) =>
            iif(
                () => {
                    this.isUpdate = url.filter((x) => x.path === 'edit').length !== 0;
                    return this.isUpdate;
                },
                this.edit$,
                this.create$
            )
        )
    );
    ngOnInit(): void {
        this.vm$.subscribe();
    }

    setClinTrakData(clinTrakSite: ClinTrakStudySiteViewModel) {
        this.clinTrakDataStateService.setStudySiteData(clinTrakSite);
        if (this.site?.info) {
            this.site.info.name = clinTrakSite.siteName;
            this.site.info.siteNumber = clinTrakSite.studySiteCenterId;
            this.site.info.pilastName = clinTrakSite.primaryInvestigator;
            this.site.info.clintrakStatus = clinTrakSite.statusType;
            this.site.primaryCrcEmailToInvite = clinTrakSite.primaryCrcEmail;

            this.site.status = <SiteStatus>{
                statusId: SiteStatusEnum.Created,
                statusName: SiteStatusEnum.Created.toString(),
            };
        }

        this.clinTrakDataService
            .getStudySiteAddress(clinTrakSite.studySiteId)
            .pipe(
                take(1),
                tap((siteAddress) => {
                    if (this.site?.address) {
                        this.site.address.line1 = siteAddress?.address1;
                        this.site.address.line2 = siteAddress?.address2;
                        this.site.address.city = siteAddress?.city;
                        this.site.address.country = siteAddress?.country;
                        this.site.address.postalCode = siteAddress?.postalCode;
                        this.site.address.stateOrProvince = siteAddress?.stateOrProvinceName;
                        this.site.isShippingAddressSameAsPrimary = true;
                    }
                }),
                finalize(() => this.stateService.setNewSite(this.site))
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.stateService.clearStore();
    }

    detectAddressChange: (event: Address) => void = (event: Address) => {
        if (this.site) this.site.address = updateSiteProperties(event, this.site.address);
    };

    detectShippingAddressChange: (event: Address) => void = (event: Address) => {
        if (this.site) this.site.shippingAddress = updateSiteProperties(event, this.site.shippingAddress);
    };

    detectIsShippingRadioChange: (event: boolean) => void = (event: boolean) => {
        if (this.site) this.site.isShippingAddressSameAsPrimary = event;
    };

    detectServicesChange: (event: SiteServices) => void = (event: SiteServices) => {
        if (this.site) this.site.services = updateSiteProperties(event, this.site.services);
    };

    detectPaymentChange: (event: SitePayment) => void = (event: SitePayment) => {
        if (this.site) this.site.payment = updateSiteProperties(event, this.site.payment);
    };

    detectCRCChange: (event: SiteCRC[]) => void = (event: SiteCRC[]) => {
        if (this.site) this.site.siteCRCs = event;
    };

    detectInfoChange: (event: SiteInfo) => void = (event: SiteInfo) => {
        if (this.site) this.site.info = updateSiteProperties(event, this.site.info);
    };

    detectCrcToInviteChange: (event: string) => void = (event: string) => {
        if (this.site) this.site.primaryCrcEmailToInvite = event;
    };

    changeStatus(statusName: string): void {
        if (this.site)
            this.siteService
                .changeSiteStatus(statusName, this.site.id)
                .pipe(
                    take(1),
                    finalize(() => {
                        this.stateService.setSite(this.site.id);
                        this.snackbarService.openInfoSnackbar(`Status changed to ${statusName}`);
                    })
                )
                .subscribe();
    }

    validateAndPost: () => void = () => {
        let model = transformToSiteDataModel(this.site);
        this.siteService.createSite(model).subscribe({
            next: (response) => {
                this.location.back();
                this.snackbarService.openInfoSnackbar('Created new site');
            },
            error: (error) => {
                this.handleSiteSaveError(error);
            },
        });
    };

    validateAndUpdate: (event: any) => void = (event: any) => {
        let model = transformToSiteDataModel(this.site);
        this.siteService.updateSite(model).subscribe({
            next: (result) => {
                let card = this.cardService.getState();
                if (card?.cardForm) {
                    card.cardForm.toggleEditMode();
                    this.cardService.clearCurrentState(card);
                }
                this.stateService.setSiteAndStudyUsers(model.id);
                if (event?.type !== 'incrementalSave') {
                    this.location.back();
                }
                this.snackbarService.openInfoSnackbar(`Updated Site ${this.site.info.name}`);
            },
            error: (error) => {
                this.handleSiteSaveError(error);
            },
        });
    };

    private handleSiteSaveError(error) {
        const dialogConfig = new MatDialogConfig();
        this.stateService.setSite(this.site.id);
        switch (error.status) {
            case 400:
                switch (error.error.errorReasonCode) {
                    case 'DuplicateSite':
                        dialogConfig.disableClose = false;
                        dialogConfig.autoFocus = true;
                        dialogConfig.maxWidth = 350;
                        dialogConfig.minWidth = 350;

                        dialogConfig.data = {
                            title: 'Duplicate Entry',
                            bodyText: 'This site cannot be created because a site with the same number already exists',
                        };

                        this.dialog.open(MedpaceMessageModalComponent, dialogConfig);
                        break;
                    case 'DuplicateSiteInstitutionName':
                        dialogConfig.disableClose = false;
                        dialogConfig.autoFocus = true;
                        dialogConfig.maxWidth = 350;
                        dialogConfig.minWidth = 350;

                        dialogConfig.data = {
                            title: 'Duplicate Entry',
                            bodyText:
                                'This site cannot be created because a site with the same Institution Name already exists',
                        };

                        this.dialog.open(MedpaceMessageModalComponent, dialogConfig);
                        break;
                }
                break;
        }
    }

    getTitleLabel(): string {
        return this.isUpdate ? 'Edit Site' : 'Create New Site';
    }
}
